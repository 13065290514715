import React, { Component } from 'react'
import { Row, Col } from 'react-flexbox-grid'
import Layout from '../../../components/dfa-theme/layout/hcp'
import RequestForm from '../../../components/request-a-rep-form';
import SourceEmitter from '../../../lib/emitter';

const metaTags = {
  description: 'Request a visit from a representative for SUPPRELIN® LA (histrelin acetate) subcutaneous implant.',
  keywords: 'Homepage',
  title: 'Request a Rep | SUPPRELIN® LA (histrelin acetate)'
}

const externalLinksMetaTags = {
  category: 'External Link',
  action: 'Click',
  label: 'Deerfield Homepage',
}

let Subscription_Form_Submit = null;


class RequestPage extends Component  {

  constructor() {
    super()
     this.state = {
        formCompleted: false,
     };
  }

  componentDidMount() {
    Subscription_Form_Submit = SourceEmitter.addListener(
      'FormSubmitted',
      data => {
        if (data) {
          this.setState({ formCompleted: true });
        }
      }
    );
  }

  componentWillUnmount() {
    Subscription_Form_Submit && Subscription_Form_Submit.remove();
  }

  render () {
    const { formCompleted } = this.state;
    return (

        <Layout meta={metaTags}>
        <Row>
          <Col xs={12}>
            { !formCompleted &&
                  <h1>REQUEST A <span className='block_nowrap'>SUPPRELIN<sup>&reg;</sup> LA</span> SALES REPRESENTATIVE</h1>
            }
            { !formCompleted &&
                                  <p>This is intended for US healthcare providers only.<br/>
                                  Please enter your contact information below.<br/>All fields are required unless marked optional.</p>
            }
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={6}>
            { !formCompleted && <RequestForm /> }
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
          { formCompleted && <h4 style={{fontWeight: 'normal', paddingTop: '2rem'}}><strong>Thank You.</strong> <br/><br/>THANK YOU FOR YOUR INTEREST IN <nobr>SUPPRELIN<sup>&reg;</sup> LA.</nobr></h4>}

            { !formCompleted &&
              <p>Endo Pharmaceuticals Inc. understands that your privacy is important. By providing your name, address, and other requested information, you are giving Endo Pharmaceuticals Inc. and other parties working with us permission to communicate with you about SUPPRELIN<sup>&reg;</sup> LA or other products, services, and offers from Endo Pharmaceuticals Inc. We will not sell your name or other personal information to any party for its marketing use. To view the privacy policy, please visit <a href='https://www.endo.com/privacy-legal' target="_blank" className="link-underline">Endo.com/privacy-legal</a>.</p>

            }
          </Col>
        </Row>
      </Layout>
      )
  }
}

export default RequestPage
