import React, { Component } from 'react';
import { Button, FormGroup, InputGroup, ButtonGroup } from "@blueprintjs/core";
import { loadReCaptcha, ReCaptcha } from 'react-recaptcha-v3'


import axios from 'axios';
import SourceEmitter from '../../lib/emitter';
import _STATES_ from './states'

import './form.scss'
import { TH_LIST } from '@blueprintjs/icons/lib/esm/generated/iconContents';

const DFA_API_SERVICES_URL = process.env.DFA_API_SERVICES_URL;
const RECAPTCHA_SITE_KEY = "6LcbJEAaAAAAAHX8XMVSFknQqWnfKSj18EdNXRLW";


const specialties = [
  "Pediatric Endocrinologist",
  "Pediatric Endocrinology Nurse",
  "Other"
]

class RequestForm extends Component {
    constructor(props) {
        super()
        this.state = {
            emailValue: '',
            firstNameValue: '',
            lastNameValue: '',
            practiceNameValue: '',
            specialtyValue: '',
            streetValue: '',
            cityValue: '',
            stateValue: '',
            zipValue: '',
            phoneValue: '',
            sendersFullNameValue: '',
            sendersEmailValue: '',
            FormError: false,
            emailError: false,
            firstNameError: false,
            lastNameError: false,
            practiceNameError: false,
            specialtyError: false,
            streetError: false,
            cityError: false,
            stateError: false,
            zipError: false,
            phoneError: false,
            sendersFullName_Error: false,
            sendersemailError: false,
            checkingReCaptchaForSubmit: false,
            isSubmitting: false,
        };

        this.handleEmail = this.handleEmail.bind(this);
        this.handleTextChange = this.handleTextChange.bind(this);
        this.handleSendersEmail = this.handleSendersEmail.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleCancel = this.handleCancel.bind(this);
        this.validateEmail = this.validateEmail.bind(this);
        this.handleStateChange = this.handleStateChange.bind(this);
        this.handleSpecialtyChange = this.handleSpecialtyChange.bind(this);
    }

    handleEmail = val => {
        this.setState({
          emailValue: val.target.value
        });

      };

    handleTextChange = evt => {
     this.setState({ [evt.target.name]: evt.target.value });
    };

    handleSendersEmail = val => {
      this.setState({
        sendersEmailValue: val.target.value
      });
    };

    validateEmail = _email => {
        const emailREGEX = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

        return emailREGEX.test(_email);
    };

    validateTextField = (_val,  errorFieldName) => {

      if (!_val.length > 0 ) {
        this.setState({
          FormError: true,
          [errorFieldName]: true,
        });
      } else {
        this.setState({
          [errorFieldName]: false,
        });
      }

      return ( _val.length > 0 )
    }


    validForm = () => {
      const validEmail = this.validateEmail(this.state.emailValue);
      const validfirstName = this.validateTextField(this.state.firstNameValue, 'firstNameError');
      const validLastName = this.validateTextField(this.state.lastNameValue, 'lastNameError');
      const validPracticeName = this.validateTextField(this.state.practiceNameValue, 'practiceNameError');
      const validAddress = this.validateTextField(this.state.streetValue, 'streetError');
      const validCity = this.validateTextField(this.state.cityValue, 'cityError')
      const validState = this.validateTextField(this.state.stateValue, 'stateError');
      const validZip = this.validateTextField(this.state.zipValue, 'zipError');


      // is email valid
      if (!validEmail) {
        console.log('email invalid')
        this.setState({
          FormError: true,
          emailError: true,
        });
        return false
      }

      if (!validfirstName || !validLastName || !validPracticeName || !validAddress || !validCity || !validState || !validZip) {
        console.log('form invalid')
        return false
      }

      return true;
    }

    handleStateChange = (event) => {
      const _val = event.target.value;
      this.setState({ stateValue: _val });
    }
    handleSpecialtyChange = (event) => {
      const _val = event.target.value;
      this.setState({ specialtyValue: _val });
    }

    handleCancel(){
      console.log('cancel close')
      SourceEmitter.emit(`FormCanceled`, true);

    }

    handleSubmit = () => {
      const validEmail = this.validateEmail(this.state.emailValue)
      if ( this.validForm() ){
        this.setState({
          FormError: false,
          isSubmitting: true,
        });

        //send form data
        //axios.get/post
        const dataToSend = {
          data: {
            email: this.state.emailValue,
            first_name: this.state.firstNameValue,
            last_name: this.state.lastNameValue,
            practice_name: this.state.practiceNameValue,
            specialty: this.state.specialtyValue,
            street_address: this.state.streetValue,
            city: this.state.cityValue,
            state: this.state.stateValue,
            zip: this.state.zipValue,
            phone: this.state.phoneValue,
            client: 'endo',
            product: 'supprelin',
            specific: 'request-a-rep',
            submit_date: Date.now(),
          }
        };
        console.log(dataToSend)
        //Submit form
        axios
          .post(DFA_API_SERVICES_URL, JSON.stringify(dataToSend))
          .then(response => {
            console.log(response);
            //Clear statea
            this.setState(
                {
                  emailValue: '',
                  firstNameValue: '',
                  lastNameValue: '',
                  practiceNameValue: '',
                  specialtyValue: '',
                  streetValue: '',
                  cityValue: '',
                  stateValue: '',
                  zipValue: '',
                  phoneValue: '',
                  sendersFullNameValue: '',
                  sendersEmailValue: '',
                  emailError: false,
                  firstNameError: false,
                  lastNameError: false,
                  practiceNameError: false,
                  specialtyError: false,
                  streetError: false,
                  cityError: false,
                  stateError: false,
                  zipError: false,
                  phoneError: false,
                  sendersemailError: false,
                  isSubmitting: false,
                }
            );
            SourceEmitter.emit(`FormSubmitted`, true);
          })
          .catch(error => {
            console.log(error);
          });
        //console.log(dataToSend);
        } else {
          alert('Error: Form fields are invalid.');
        }
      };

    componentDidMount() {
      loadReCaptcha(RECAPTCHA_SITE_KEY, () => {
        console.log('recpatcha loaded');
      });
    }

    componentWillUnmount() {
      const script = document.getElementById('recaptcha-script');
      if (script) {
          script.remove();
      }

      const recaptchaElems = document.getElementsByClassName('grecaptcha-badge');
      if (recaptchaElems.length) {
          recaptchaElems[0].remove();
      }
    }

    handleSubmitRecaptchaCheck =  () => {
      this.state.checkingReCaptchaForSubmit = true;
      this.updateRecaptchaToken();
    }

    verifyRecaptchaCallback = (recaptchaToken) => {
      // Here you will get the final recaptchaToken!!!
      console.log(recaptchaToken, "<= your recaptcha token");

      if (this.state.checkingReCaptchaForSubmit) {
        this.state.checkingReCaptchaForSubmit = false;
        this.handleSubmit();
      }
    }

    updateRecaptchaToken = () => {
      // you will get a new token in verifyCallback
      this.recaptcha.execute();
    }

    render() {
        const { emailValue, firstNameValue, lastNameValue, practiceNameValue, specialtyValue, streetValue, cityValue, stateValue, zipValue, phoneValue, cityError, stateError, zipError, unsub, emailError, firstNameError, lastNameError, practiceNameError, FormError, streetError, phoneError, isSubmitting } = this.state;
        const { email } = this.props;

        return (

            <div className='request-a-rep-form-container'>
                <FormGroup
                    helperText="Please add your first name"
                    label="First Name"
                    labelFor="text-firstName"
                    labelInfo="(required)"
                    >
                     <InputGroup id="text-firstName" intent={ (firstNameError && 'danger') || 'primary' } placeholder="Jon" large name="firstNameValue" onChange={this.handleTextChange} value={firstNameValue}  />
                </FormGroup>
                <FormGroup
                    helperText="Please add your last name"
                    label="Last Name"
                    labelFor="text-lastName"
                    labelInfo="(required)"
                    >
                     <InputGroup id="text-lastName" intent={ (lastNameError && 'danger') || 'primary' } placeholder="Smith" large name="lastNameValue" onChange={this.handleTextChange} value={lastNameValue}  />
                </FormGroup>
                <FormGroup
                    helperText="Please add your practice name"
                    label="Practice Name"
                    labelFor="text-practiceName"
                    labelInfo="(required)"
                    >
                     <InputGroup id="text-practiceName" intent={ (practiceNameError && 'danger') || 'primary' } placeholder="Practice name" large name="practiceNameValue" onChange={this.handleTextChange} value={practiceNameValue}  />
                </FormGroup>
                <FormGroup
                    helperText="Please add your specialty"
                    label="Specialty"
                    labelFor="text-specialty"
                    labelInfo="(optional)"
                    >
                        <div className="bp3-select bp3-large bp3-fill">
                          <select onChange={this.handleSpecialtyChange} className="select-report">
                              <option defaultValue value="">Choose a Specialty</option>
                              {
                                  specialties.map(item => {
                                      return <option value={item} key={item}>{item}</option>
                                  })
                              }
                          </select>
                        </div>
                </FormGroup>
                <FormGroup
                    helperText="Please add your email address"
                    label="Email Address"
                    labelFor="text-email"
                    labelInfo="(required)"
                    >
                     <InputGroup id="text-email" intent={ (emailError && 'danger') || 'primary' } placeholder="jon.smith@example.com" large name="emailValue" onChange={this.handleTextChange} value={emailValue}  />
                </FormGroup>
                <FormGroup
                    helperText="Please add your street address"
                    label="Street Address"
                    labelFor="text-streetAddress"
                    labelInfo="(required)"
                    >
                     <InputGroup id="text-streetAddress" intent={ (streetError && 'danger') || 'primary' } placeholder="123 Main St" large name="streetValue" onChange={this.handleTextChange} value={streetValue}  />
                </FormGroup>
                <FormGroup
                    helperText="Please add your city"
                    label="City"
                    labelFor="text-city"
                    labelInfo="(required)"
                    >
                     <InputGroup id="text-city" intent={ (cityError && 'danger') || 'primary' } placeholder="Malvern" large name="cityValue" onChange={this.handleTextChange} value={cityValue}  />
                </FormGroup>
                <FormGroup
                    helperText="Please select your state"
                    label="State"
                    labelFor="text-practiceName"
                    labelInfo="(required)"
                    intent={ (stateError && 'danger') || '' }
                    >
                        <div className="bp3-select bp3-large bp3-fill">
                          <select onChange={this.handleStateChange} className="select-report">
                              <option defaultValue value="">Choose a State</option>
                              {
                                  _STATES_.map(item => {
                                      return <option value={item['alpha-2']} key={item['alpha-2']}>{item.name}</option>
                                  })
                              }
                          </select>
                        </div>
                </FormGroup>
                <FormGroup
                    helperText="Please add your ZIP Code"
                    label="ZIP"
                    labelFor="text-zip"
                    labelInfo="(required)"
                    >
                     <InputGroup id="text-zip" intent={ (cityError && 'danger') || 'primary' } placeholder="19355" large name="zipValue" onChange={this.handleTextChange} value={zipValue}  />
                </FormGroup>
                <FormGroup
                    helperText="Please add your phone number"
                    label="Phone Number"
                    labelFor="text-phoneNumber"
                    labelInfo="(optional)"
                    >
                     <InputGroup id="text-phoneNumber" intent={ (phoneError && 'danger') || 'primary' } placeholder="" name="phoneValue" large onChange={this.handleTextChange} value={phoneValue}  />
                </FormGroup>
                <ButtonGroup style={{ minWidth: 200 }}>
                  <Button intent="primary" type='submit' large text="Submit" onClick={this.handleSubmitRecaptchaCheck} disabled={isSubmitting} />
                </ButtonGroup>
                <ReCaptcha
                    ref={ref => this.recaptcha = ref}
                    sitekey={RECAPTCHA_SITE_KEY}
                    // action='submit'
                    verifyCallback={this.verifyRecaptchaCallback}
                />
            </div>
        );
    }
};

export default RequestForm;
